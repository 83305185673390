<template>
    <div class="ww-popup-section-base-versions ww-scroll-bar">
        <wwTable
            class="table"
            :data="sectionBaseVersions"
            :loading="isLoading"
            :titles="tableTitles"
            no-data-msg="No versions"
        >
            <template v-slot:item="{ item }">
                <tr @click="setActiveVersion(item)">
                    <td class="column-active">
                        <wwLoaderSmall class="column-loader" v-if="isBuilding && item.isLatest" />
                        <wwManagerRadio v-else :value="item.active" @change="setActiveVersion(item)" />
                    </td>
                    <td class="column-version">
                        <b>v{{ item.version || '-' }}</b>
                    </td>
                    <td class="column-package-version">
                        <b>{{ item.packageVersion || '-' }}</b>
                    </td>
                    <td class="column-date">{{ item.createdAt | moment('from', 'now') }}</td>
                </tr>
            </template>
        </wwTable>
    </div>
</template>

<script>
import services from '@/services';

export default {
    name: 'wwPopupSectionBaseVersions',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            isLoading: false,
            tableTitles: [
                {
                    text: 'Is Active',
                    sortBy: 'active',
                },
                {
                    text: 'Version',
                    sortBy: 'version',
                },
                {
                    text: 'Package Version',
                    sortBy: 'packageVersion',
                },
                {
                    text: 'Date',
                    sortBy: 'date',
                },
            ],
            sectionBaseVersions: [],
        };
    },
    computed: {
        isBuilding() {
            const organizationId = this.$store.getters['v2/getCurrentOrganization'].id;
            const sectionBase = this.$store.getters['v2/getSectionBases'](organizationId).find(
                sectionBase => sectionBase.id === this.options.data.sectionBaseId
            );
            if (!sectionBase) return false;
            return sectionBase.sourceCodeStatus.progress !== 100;
        },
    },
    methods: {
        setActiveVersion(item) {
            if (this.isBuilding && item.isLatest) return;
            for (const version of this.sectionBaseVersions) {
                version.active = false;
            }

            item.active = true;
            this.options.result.sectionBaseVersion = item;
        },
    },
    async mounted() {
        this.isLoading = true;
        this.sectionBaseVersions = await services.api.sourceCode.getSectionBaseVersions(
            this.options.data.sectionBaseId
        );
        this.isLoading = false;
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-section-base-versions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table {
        width: 100%;
        .column {
            &-package-version {
                width: 100%;
            }
            &-date {
                white-space: nowrap;
            }
            &-loader {
                transform: scale(0.7);
            }
        }
    }
}
</style>
