<template>
    <div class="ww-manager-menu" :style="{ top: y ? y + 'px' : '50%', left: x ? x + 'px' : '50%' }">
        <div class="menu" :class="{ show: d_show, up: d_up }">
            <div
                class="action"
                :class="[
                    action.color,
                    action.disabled ? 'disabled' : '',
                    action.title ? 'title' : '',
                    action.subtitle ? 'subtitle' : '',
                ]"
                v-for="(action, index) in actions"
                :key="index"
                @click="runAction(action)"
                @mouseenter="runMouseEnter(action)"
                @mouseleave="runMouseLeave(action)"
            >
                {{ action.text }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwManagerMenu',
    props: {
        actions: Array,
        item: [Boolean, String, Array, Object, Number],
        x: Number,
        y: Number,
    },
    computed: {},
    data() {
        return {
            d_show: false,
            d_up: false,
        };
    },
    methods: {
        init() {
            setTimeout(this.show, 10);
        },
        show() {
            const menu = this.$el;

            if (
                menu &&
                window.innerHeight - (menu.getBoundingClientRect().y + menu.getBoundingClientRect().height * 2) < 300
            ) {
                this.d_up = true;
            } else {
                this.d_up = false;
            }

            this.d_show = true;
        },
        close() {
            this.d_show = false;
        },
        runAction(action) {
            this.$emit('runAction', action);
        },
        runMouseEnter(action) {
            this.$emit('runMouseEnter', action);
        },
        runMouseLeave(action) {
            this.$emit('runMouseLeave', action);
        },
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.ww-manager-menu {
    position: absolute;
    z-index: 105;

    .menu {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 150px;
        background-color: var(--ww-color-white);
        border: 1px solid var(--ww-color-dark-200);
        box-shadow: 0px 2px 8px rgba(30, 35, 36, 0.12);

        transition: transform 0.3s ease, opacity 0.3s ease;
        transform-origin: 100% 0%;
        transform: scale(0.95);
        opacity: 0;
        pointer-events: none;
        border-radius: 3px;
        text-align: left;
        overflow: hidden;
        padding: var(--ww-spacing-02);

        &.up {
            top: auto;
            bottom: 50%;
            transform-origin: 100% 100%;
        }

        &.show {
            border-radius: 8px;
            pointer-events: all;
            transform: scale(1);
            opacity: 1;
        }

        .action {
            padding: var(--ww-spacing-01) var(--ww-spacing-01);
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }

            &.red {
                color: var(--ww-color-red-500);
            }

            &.purple {
                color: var(--ww-color-purple-500);
            }

            &.blue {
                color: var(--ww-color-blue-500);
            }

            &.yellow {
                color: var(--ww-color-yellow-500);
            }

            &.disabled {
                cursor: not-allowed;
                color: var(--ww-color-dark-400);
            }

            &.title {
                cursor: default;
                color: var(--ww-color-dark-500);
                border-bottom: 1px solid var(--ww-color-dark-400);
                margin-bottom: var(--ww-spacing-02);
                &:hover {
                    text-decoration: none;
                }
            }

            &.subtitle {
                cursor: default;
                font-size: var(--ww-font-size-04);
                color: var(--ww-color-dark-500);
                margin-top: var(--ww-spacing-03);
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
</style>
