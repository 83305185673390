export default {
    en: {
        accountSettings: 'Account settings',
        mail: 'Email',
        name: 'Name',
        billing: 'Billing',
        billingInfo: 'Billing info',
        noAddress: 'no address',
        updateAdress: 'Update address',
        accountBilled: 'This accound is billed to',
        noCard: 'no card registered',
        updateCard: 'Update card',
        activeCard: 'Active Card',
        invoices: 'Invoices',
        paid: 'Paid',
        notPaid: 'Not paid',
        notCustomer: 'You do not have any paying plan registered',
        endingCard: '<span class="capitalize">%{cardType}</span> card ending in %{cardNumb}',
        publishHook: 'After publish hook',
        selfHost: 'Self-Hosting',
        wewebPreviewURL: 'weweb-server URL',
        selfHostCopyKey: 'Copy Key',
        selfHostPublicKey: 'Public Key',
        selfHostPrivateKey: 'Private Key',
        selfHostPublicKeyGenerate: 'Generate new Public Key',
        selfHostPrivateKeyGenerate: 'Generate new Private Key',
    },
    fr: {
        accountSettings: 'Mon compte',
        mail: 'Email',
        name: 'Nom',
        billing: 'Facturation',
        billingInfo: 'Détails de facturation',
        noAddres: "pas d'adresse",
        updateAdress: "Mettre à jour l'adresse",
        accountBilled: 'Ce compte est facturé à',
        noCard: 'aucune carte enregistrée',
        updateCard: 'Mettre à jour',
        activeCard: 'Carte Active',
        invoices: 'Factures',
        paid: 'Payé',
        notPaid: 'Impayé',
        notCustomer: "Vous n'avez aucun forfait payant enregistré",
        endingCard: 'carte <span class="capitalize">%{cardType}</span> se terminant par %{cardNumb}',
        publishHook: 'After publish hook',
        wewebPreviewURL: 'weweb-server URL',
    },
};
