<template>
    <div class="ww-popup-website-publish-logs">
        <label class="log__label caption-s" for="filters">
            Filters
            <span class="log__label-required">optional</span>
        </label>
        <div class="log__filters log__input">
            <div class="log__filters-elem">
                <wwManagerRadio v-model="filters.info" />
                <span class="m-left">Info</span>
            </div>
            <div class="log__filters-elem">
                <wwManagerRadio v-model="filters.warn" />
                <span class="m-left">Warnings</span>
            </div>
            <div class="log__filters-elem">
                <wwManagerRadio v-model="filters.error" />
                <span class="m-left">Errors</span>
            </div>
            <div class="log__filters-elem" v-if="user.admin">
                <wwManagerRadio v-model="filters.debug" />
                <span class="m-left">Debug</span>
            </div>
        </div>
        <div ref="logs" class="logs__container ww-scroll-bar">
            <div v-for="(log, index) in logs" :key="index" class="log caption-l" :class="[`-${log.level}`]">
                <span v-html="getComputedLog(log)"></span>
                <span class="log__level label-l" :class="[`-${log.level}`]">{{ log.level }} </span>
            </div>
        </div>
        <div v-if="!logs.length" class="log label-l">
            <wwLoader :loading="true" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'wwPopupWebsitePublishLogs',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            filters: {
                info: true,
                debug: false,
                warn: true,
                error: true,
            },
            websiteId: undefined,
            publishStatusId: undefined,
        };
    },
    watch: {
        logs() {
            if (!this.$refs || !this.$refs.logs) return;
            this.$nextTick(() => this.$refs.logs.scroll({ top: 100000000000, behavior: 'smooth' }));
        },
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
            designPublishStatus: 'v2/getDesignPublishStatus',
        }),
        publishStatus() {
            return this.designPublishStatus.find(elem => elem.id === this.publishStatusId);
        },
        logs() {
            return ((this.publishStatus && this.publishStatus.logs) || [])
                .filter(log => this.filters[log.level])
                .sort((logA, logB) => {
                    return logA.date < logB.date ? -1 : 1;
                });
        },
    },
    methods: {
        ...mapActions({
            fetchDesignPublishLogs: 'v2/fetchDesignPublishLogs',
        }),
        getComputedLogPage(log) {
            switch (log.data.code) {
                case 'PAGE_COLLECTION_NOT_FOUND':
                    return `Page <b>${log.data.name}</b> is bound to a collection that no longer exist.`;
                case 'PAGE_SLUG_DUPLICATE':
                    return `Page <b>${log.data.name}</b> with slug <b>${log.data.slug}</b> has the same slug as another page.`;
                case 'PAGE_SLUG_NOT_DEFINED':
                    return `Page <b>${log.data.name}</b> has no slug defined for lang ${log.data.lang}.`;
                case 'PAGE_META_NOT_DEFINED': {
                    const lang = log.data.lang ? ` in lang <b>${log.data.lang}</b>` : '';
                    return `Page <b>${log.data.name}</b> with slug <b>${log.data.slug}</b> has no <b>${log.data.key}</b>${lang}.`;
                }
                default: {
                    const lang = log.data.variantsCount ? ` with <b>${log.data.variantsCount}</b> variants` : '';
                    return `Page <b>${log.data.name}</b>${lang} with lang(s) <b>${
                        log.data.langs && log.data.langs.join(', ')
                    }</b>.`;
                }
            }
        },
        getComputedLogSourceCode(log) {
            return `${log.data.type}'s component <b>${log.data.name}</b> added.`;
        },
        getComputedLogError(log) {
            if (log.data.error) return log.data.error;
            return `
            <b style="display: flex; align-items: center; color: var(--ww-color-red-500)">
                An error occured while publishing the project.
                <button class="ww-editor-button -primary -red -small" style="margin-left: var(--ww-spacing-02)" onclick="window.$crisp.push(['do', 'chat:open'])">contact us</button>
            </b>
            `;
        },
        getComputedLogSuccess(log) {
            switch (log.data.code) {
                case 'PUBLISH_STARTED':
                    return '<b style="color: var(--ww-color-green-500)">The publication has started.</b>';
                case 'PUBLISH_ENDED':
                    return '<b style="color: var(--ww-color-green-500)">The project has been published successfully.</b>';
            }
        },
        getComputedLogMessage(log) {
            return `[MESSAGE] : ${log.data.message}`;
        },
        getComputedLog(log) {
            let message = `[${new Date(log.date).toLocaleString()}] `;
            switch (log.type) {
                case 'page':
                    message += this.getComputedLogPage(log);
                    break;
                case 'source-code':
                    message += this.getComputedLogSourceCode(log);
                    break;
                case 'error':
                    message += this.getComputedLogError(log);
                    break;
                case 'success':
                    message += this.getComputedLogSuccess(log);
                    break;
                case 'message':
                    message += this.getComputedLogMessage(log);
                    break;
            }

            return message;
        },
        async fetchLogs() {
            try {
                await this.fetchDesignPublishLogs({ websiteId: this.websiteId, publishStatusId: this.publishStatusId });
            } catch (err) {
                wwLib.wwLog.error(err);
            }
        },
    },
    created() {
        this.websiteId = this.options.data.websiteId;
        this.publishStatusId = this.options.data.publishStatusId;
    },
    mounted() {
        this.fetchLogs();
    },
};
</script>

<style scoped lang="scss">
.ww-popup-website-publish-logs {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    width: 100%;
    .logs__container {
        overflow: auto;
    }
    .log {
        display: flex;
        width: 100%;
        padding: var(--ww-spacing-03) var(--ww-spacing-01);
        border-bottom: 1px solid var(--ww-color-dark-300);
        color: var(--ww-color-dark-600);
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            margin-bottom: var(--ww-spacing-03);
        }
        &__filters {
            display: flex;
            justify-content: space-between;
            padding: 0 var(--ww-spacing-01);
            &-elem {
                display: flex;
                align-items: center;
            }
        }
        &.-warn {
            background-color: var(--ww-color-yellow-100);
        }
        &.-error {
            background-color: var(--ww-color-red-100);
        }
        &.-success {
            color: var(--ww-color-green-500);
        }
        &__level {
            margin-left: auto;
            &.-info {
                color: var(--ww-color-blue-500);
            }
            &.-debug {
                color: var(--ww-color-purple-500);
            }
            &.-warn {
                color: var(--ww-color-yellow-500);
            }
            &.-error {
                color: var(--ww-color-red-500);
            }
        }
    }
    .m-left {
        margin-left: var(--ww-spacing-02);
    }
}
</style>
