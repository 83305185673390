import gql from 'graphql-tag';

/*=============================================m_ÔÔ_m=============================================\
    Design Domain
\================================================================================================*/
export const DESIGN_DOMAIN_FRAGMENT = gql`
    fragment DesignDomainFragment on DesignDomain {
        id
        name
        status
        dnsRecords
        errorMessage
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Publish
\================================================================================================*/
export const PUBLISH_STATUS_FRAGMENT = gql`
    fragment PublishStatusFragment on PublishStatus {
        id
        designId
        progress
        message
        status
        commit
        description
        user {
            id
            name
        }
        cacheVersion
        updatedAt
        createdAt
        env
        activeProd
        activeStaging
        activeBackup
        activeCheckpoint
    }
`;

export const PUBLISH_LOG_FRAGMENT = gql`
    fragment PublishLogFragment on PublishLog {
        id
        publishStatusId
        level
        type
        data
        date
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Designs
\================================================================================================*/
export const DESIGN_VERSION_FRAGMENT = gql`
    fragment DesignVersionFragment on DesignVersion {
        id
        createdAt
        creatorUser {
            name
            email
        }
        type
        data {
            name
            percent
        }
        cacheVersion
    }
`;
export const DESIGN_METRICS_FRAGMENT = gql`
    fragment DesignMetricsFragment on DesignMetrics {
        designId
        publishedPageCount
        views
        staticCollectionSize
        cachedCollectionSize
        totalCollectionSize
        totalUserFileSize
    }
`;

export const DESIGN_FRAGMENT = gql`
    fragment DesignFragment on Design {
        id
        name
        domain {
            ...DesignDomainFragment
        }
        designVersions {
            ...DesignVersionFragment
        }
        publishStatus {
            ...PublishStatusFragment
        }
        previewUrl
        langs
        publishHook
        plan
        planInterval
        stripeSubscriptionId
        features
        openedAt
        isPlanCancelled
        createdAt
        updatedAt
    }
    ${DESIGN_VERSION_FRAGMENT}
    ${DESIGN_DOMAIN_FRAGMENT}
    ${PUBLISH_STATUS_FRAGMENT}
`;
