<template>
    <div class="ww-popup-rename-website ww-scroll-bar">
        <div class="container">
            <label for="name" class="label label-m">{{ $t('websites.settings.websiteName') }}</label>
            <input
                name="name"
                class="input ww-editor-input -large"
                type="text"
                :placeholder="$t('utils.name')"
                v-model="websiteName"
                autofocus
            />
            <span class="requiredLabel label label-m">{{ $t('utils.required') }}</span>

            <label for="publishHook" class="label label-m" style="margin-top: var(--ww-spacing-03)">{{
                $t('websites.settings.websitePublishHook')
            }}</label>
            <input
                name="publishHook"
                class="input ww-editor-input -large"
                type="text"
                :placeholder="$t('websites.settings.websitePublishHookPlaceholder')"
                v-model="publishHook"
                autofocus
            />

            <template v-if="canSelfHost">
                <label for="wewebPreviewURL" class="label label-m" style="margin-top: var(--ww-spacing-03)">{{
                    $t('websites.settings.wewebPreviewURL')
                }}</label>
                <input
                    name="wewebPreviewURL"
                    class="input ww-editor-input -large"
                    type="text"
                    :placeholder="$t('websites.settings.wewebPreviewURLPlaceholder')"
                    v-model="wewebPreviewURL"
                    autofocus
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import services from '@/services';

export default {
    name: 'wwPopupWebsiteRename',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            websiteName: '',
            publishHook: '',
            wewebPreviewURL: '',
        };
    },
    watch: {
        websiteName() {
            this.setButtonStatus();
        },
    },
    computed: {
        ...mapGetters({
            design: 'v2/getCurrentDesign',
            workspace: 'v2/getCurrentOrganization',
        }),
        canSelfHost() {
            return (this.design.features || {}).selfHost;
        },
    },
    methods: {
        ...mapActions({
            fetchDesign: 'v2/fetchDesign',
        }),
        init() {
            this.websiteName = this.options.data.design.name;
            this.publishHook = this.options.data.design.publishHook;
            this.options.setButtonState('NEXT', 'disabled');
        },
        setButtonStatus() {
            this.options.setButtonState('NEXT', this.websiteName.length ? 'ok' : 'disabled');
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                await services.api.design.updateDesign(this.design.id, {
                    name: this.websiteName,
                    publishHook: this.publishHook,
                });
                this.fetchDesign({ organizationId: this.workspace.id, designId: this.design.id });

                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Project settings saved</b>',
                    },
                    color: 'green',
                });
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while saving project settings</b>',
                        fr: '<b>Erreur lors du renommage du projet</b>',
                    },
                    color: 'red',
                });
            }
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-rename-website {
    width: 100%;
    display: flex;
    justify-content: center;

    .container {
        margin-top: var(--ww-spacing-04);
        width: 100%;
        display: flex;
        flex-direction: column;
        .label {
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
        }
        .input {
            width: 100%;
        }
        .requiredLabel {
            position: absolute;
            right: 0;
            color: var(--ww-color-dark-400);
        }
        .result {
            margin: var(--ww-spacing-04) auto;
            &.error {
                color: var(--ww-color-red-500);
            }
            &.valid {
                color: var(--ww-color-green-500);
            }
        }
    }
}
</style>
