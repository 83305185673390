import apollo from '@/apollo';
import {
    // Design
    GET_DESIGNS,
    GET_DESIGNS_METRICS,
    GET_DESIGN,
    // GET_DESIGN_INFO,
    // GET_DESIGNS_TO_DUPLICATE,
    // CREATE_EMPTY_DESIGN,
    UPDATE_DESIGN,
    DELETE_DESIGN,
    UNPUBLISH_DESIGN,
    DUPLICATE_DESIGN,
    // Design version
    GET_DESIGN_VERSIONS,
    // DUPLICATE_DESIGN_VERSION,
    DELETE_DESIGN_VERSION,
    CHANGE_DESIGN_VERSION_TYPE,
    // Section
    // GET_SECTIONS_UPDATES,
    // UPDATE_SECTIONS_VERSIONS,
    // Domain
    DOMAIN_CHECK,
    DOMAIN_ADD,
    DOMAIN_CONNECT,
    DELETE_DOMAIN_NAME,
    DOMAIN_RETRY,
    // // Redirection
    // GET_REDIRECTION,
    // GET_DESIGN_REDIRECTION,
    // CREATE_REDIRECTION,
    // UPDATE_REDIRECTION,
    // DELETE_REDIRECTION,
    // // Image
    // GET_DESIGN_IMAGES,
    // EDIT_DESIGN_IMAGE,
    // // Save history
    // GET_SAVE_HISTORY,
    // Publish
    GET_PUBLISH_STATUS,
    GET_PUBLISH_STATUSES,
    GET_PUBLISH_LOGS,
    PUBLISH,
    PROMOTE,
    CHECKPOINT,
    // // Form config
    // GET_FORM_CONFIG,
    // CREATE_FORM_CONFIG,
    // UPDATE_FORM_CONFIG,
    // DELETE_FORM_CONFIG,
    // // Page
    // GET_PAGE,
    // GET_PAGE_WITH_LINKED_SECTION,
    // SET_HOME_PAGE,
    // CREATE_PAGE,
    // UPDATE_PAGE,
    // DELETE_PAGE,
    // Template
    GET_TEMPLATES,
    // CREATE_TEMPLATE,
    // UPDATE_TEMPLATE,
    // DELETE_TEMPLATE,
    IS_EXPORT_ZIP_EXIST,
    GET_EXPORT_ZIP_STATUS,
    GENERATE_EXPORT_ZIP,
    SET_ACTIVE_CACHE_VERSION,
    GET_CACHE_VERSIONS,
    GET_SELF_HOST_DOMAIN,
    UPDATE_SELF_HOST_DOMAIN,
} from './design.graphql';

export default {
    /*=============================================m_ÔÔ_m=============================================\
        Design
    \================================================================================================*/
    async getDesigns(organizationId) {
        const result = await apollo.query({
            query: GET_DESIGNS,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGNS');

        return result.data.getDesigns;
    },
    async getDesignsMetrics(organizationId) {
        const result = await apollo.query({
            query: GET_DESIGNS_METRICS,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGNS_METRICS');

        return result.data.getDesignsMetrics;
    },
    async getDesign(designId) {
        const result = await apollo.query({
            query: GET_DESIGN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGN');
        return result.data.getDesign;
    },
    // async getDesignInfo(designId) {
    //     const result = await apollo.query({
    //         query: GET_DESIGN_INFO,
    //         variables: {
    //             designId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_DESIGN_INFO');

    //     return result.data.getDesignInfo;
    // },
    // async getDesignsToDuplicate() {
    //     const result = await apollo.query({
    //         query: GET_DESIGNS_TO_DUPLICATE,
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_DESIGNS_TO_DUPLICATE');

    //     return result.data.getDesignsToDuplicate;
    // },
    // async createEmptyDesign(organizationId, name) {
    //     const result = await apollo.mutate({
    //         mutation: CREATE_EMPTY_DESIGN,
    //         variables: {
    //             organizationId,
    //             name,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_CREATE_EMPTY_DESIGN');

    //     return result.data.createEmptyDesign;
    // },
    async updateDesign(
        designId,
        {
            name,
            icons,
            headScripts,
            bodyScripts,
            homePageId,
            manifest,
            langs,
            previewUrl,
            publishHook,
            prerenderDisabled,
        }
    ) {
        const result = await apollo.mutate({
            mutation: UPDATE_DESIGN,
            variables: {
                designId,
                name,
                icons,
                headScripts,
                bodyScripts,
                homePageId,
                manifest,
                langs,
                previewUrl,
                publishHook,
                prerenderDisabled,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_DESIGN');

        return result.data.updateDesign;
    },
    async deleteDesign(designId) {
        const result = await apollo.mutate({
            mutation: DELETE_DESIGN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_DESIGN');

        return result.data.deleteDesign;
    },
    async unpublishDesign(designId) {
        const result = await apollo.mutate({
            mutation: UNPUBLISH_DESIGN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UNPUBLISH_DESIGN');

        return result.data.unpublishDesign;
    },
    async deleteDomainName(designId) {
        const result = await apollo.mutate({
            mutation: DELETE_DOMAIN_NAME,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_DOMAIN_NAME');

        return result.data.deleteDomainName;
    },
    async domainRetry(designId) {
        const result = await apollo.mutate({
            mutation: DOMAIN_RETRY,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_RETRY');

        return result.data.deleteDomainName;
    },
    async duplicateDesign(organizationId, designId, designName, langs) {
        const result = await apollo.mutate({
            mutation: DUPLICATE_DESIGN,
            variables: {
                organizationId,
                designId,
                designName,
                langs,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DUPLICATE_DESIGN');

        return result.data.duplicateDesign;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Design version
    \================================================================================================*/
    async getDesignVersions(designId) {
        const result = await apollo.query({
            query: GET_DESIGN_VERSIONS,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGN_VERSIONS');

        return result.data.getDesignVersions;
    },
    // async duplicateDesignVersion(designVersionId, type, data) {
    //     const result = await apollo.mutate({
    //         mutation: DUPLICATE_DESIGN_VERSION,
    //         variables: {
    //             designVersionId,
    //             type,
    //             data,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_DUPLICATE_DESIGN_VERSION');

    //     return result.data.duplicateDesignVersion;
    // },
    async deleteDesignVersion(designVersionId) {
        const result = await apollo.mutate({
            mutation: DELETE_DESIGN_VERSION,
            variables: {
                designVersionId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_DESIGN_VERSION');

        return result.data.deleteDesignVersion;
    },
    async changeDesignVersionType(designVersionId, type) {
        const result = await apollo.mutate({
            mutation: CHANGE_DESIGN_VERSION_TYPE,
            variables: {
                designVersionId,
                type,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CHANGE_DESIGN_VERSION_TYPE');

        return result.data.changeDesignVersionType;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Section
    \================================================================================================*/
    // async getSectionsUpdates(designId) {
    //     const result = await apollo.query({
    //         query: GET_SECTIONS_UPDATES,
    //         variables: {
    //             designId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_SECTIONS_UPDATES');

    //     return result.data.getSectionsUpdates;
    // },
    // async updateSectionsVersions(designId) {
    //     const result = await apollo.mutate({
    //         mutation: UPDATE_SECTIONS_VERSIONS,
    //         variables: {
    //             designId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_UPDATE_SECTIONS_VERSIONS');

    //     return result.data.updateSectionsVersions;
    // },
    /*=============================================m_ÔÔ_m=============================================\
        Domain
    \================================================================================================*/
    async domainCheck(domainName) {
        const result = await apollo.query({
            query: DOMAIN_CHECK,
            variables: {
                domainName,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_CHECK');

        return result.data.domainCheck;
    },
    async domainAdd(designId, domainNames) {
        const result = await apollo.mutate({
            mutation: DOMAIN_ADD,
            variables: {
                designId,
                domainNames,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_ADD');

        return result.data.domainAdd;
    },
    async domainConnect(designId, domainName) {
        const result = await apollo.mutate({
            mutation: DOMAIN_CONNECT,
            variables: {
                designId,
                domainName,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_CONNECT');

        return result.data.domainConnect;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Redirection
    \================================================================================================*/
    // async getRedirection(redirectionId) {
    //     const result = await apollo.query({
    //         query: GET_REDIRECTION,
    //         variables: {
    //             redirectionId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_REDIRECTION');

    //     return result.data.getRedirection;
    // },
    // async getDesignRedirection(designId) {
    //     const result = await apollo.query({
    //         query: GET_DESIGN_REDIRECTION,
    //         variables: {
    //             designId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_DESIGN_REDIRECTION');

    //     return result.data.getDesignRedirection;
    // },
    // async createRedirection(designId, urlSource, urlTarget, targetType, pageId, status) {
    //     const result = await apollo.mutate({
    //         mutation: CREATE_REDIRECTION,
    //         variables: {
    //             designId,
    //             urlSource,
    //             urlTarget,
    //             targetType,
    //             pageId,
    //             status,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_CREATE_REDIRECTION');

    //     return result.data.createRedirection;
    // },
    // async updateRedirection(redirectionId, designId, urlSource, urlTarget, targetType, pageId, status) {
    //     const result = await apollo.mutate({
    //         mutation: UPDATE_REDIRECTION,
    //         variables: {
    //             redirectionId,
    //             designId,
    //             urlSource,
    //             urlTarget,
    //             targetType,
    //             pageId,
    //             status,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_UPDATE_REDIRECTION');

    //     return result.data.updateRedirection;
    // },
    // async deleteRedirection(redirectionId, designId) {
    //     const result = await apollo.mutate({
    //         mutation: DELETE_REDIRECTION,
    //         variables: {
    //             redirectionId,
    //             designId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_DELETE_REDIRECTION');

    //     return result.data.deleteRedirection;
    // },
    /*=============================================m_ÔÔ_m=============================================\
        Image
    \================================================================================================*/
    // async getDesignImages(designId, limit, offset, search) {
    //     const result = await apollo.query({
    //         query: GET_DESIGN_IMAGES,
    //         variables: {
    //             designId,
    //             limit,
    //             offset,
    //             search,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_DESIGN_IMAGES');

    //     return result.data.getDesignImages;
    // },
    // async editDesignImage(designId, designImageId, name, tags, defaultAlt) {
    //     const result = await apollo.mutate({
    //         mutation: EDIT_DESIGN_IMAGE,
    //         variables: {
    //             designId,
    //             designImageId,
    //             name,
    //             tags,
    //             defaultAlt,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_EDIT_DESIGN_IMAGE');

    //     return result.data.editDesignImage;
    // },
    /*=============================================m_ÔÔ_m=============================================\
        Save history
    \================================================================================================*/
    // async getSaveHistory(designId, count) {
    //     const result = await apollo.query({
    //         query: GET_SAVE_HISTORY,
    //         variables: {
    //             designId,
    //             count,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_SAVE_HISTORY');

    //     return result.data.getSaveHistory;
    // },
    /*=============================================m_ÔÔ_m=============================================\
        Publish
    \================================================================================================*/
    async getPublishStatus(designId) {
        const result = await apollo.query({
            query: GET_PUBLISH_STATUS,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_PUBLISH_STATUS');

        return result.data.getPublishStatus;
    },
    async getPublishStatuses() {
        const result = await apollo.query({
            query: GET_PUBLISH_STATUSES,
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_PUBLISH_STATUSES');

        return result.data.getPublishStatuses;
    },
    async getPublishLogs(designId, publishStatusId) {
        const result = await apollo.query({
            query: GET_PUBLISH_LOGS,
            variables: {
                designId,
                publishStatusId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_PUBLISH_LOGS');

        return result.data.getPublishLogs;
    },
    async publish(designId, commit, description, env) {
        const result = await apollo.mutate({
            mutation: PUBLISH,
            variables: {
                designId,
                commit,
                description,
                env,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_PUBLISH');

        return result.data.publish;
    },
    async promote(designId, cacheVersion) {
        const result = await apollo.mutate({
            mutation: PROMOTE,
            variables: {
                designId,
                cacheVersion,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_PROMOTE');

        return result.data.release;
    },
    async checkpoint(designId, cacheVersion) {
        const result = await apollo.mutate({
            mutation: CHECKPOINT,
            variables: {
                designId,
                cacheVersion,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CHECKPOINT');

        return result.data.checkpoint;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Form config
    \================================================================================================*/
    // async getFormConfig(designId, formConfigId) {
    //     const result = await apollo.query({
    //         query: GET_FORM_CONFIG,
    //         variables: {
    //             designId,
    //             formConfigId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_FORM_CONFIG');

    //     return result.data.getFormConfig;
    // },
    // async createFormConfig(designId, method, url, data, headers) {
    //     const result = await apollo.mutate({
    //         mutation: CREATE_FORM_CONFIG,
    //         variables: {
    //             designId,
    //             method,
    //             url,
    //             data,
    //             headers,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_CREATE_FORM_CONFIG');

    //     return result.data.createFormConfig;
    // },
    // async updateFormConfig(designId, formConfigId, method, url, data, headers) {
    //     const result = await apollo.mutate({
    //         mutation: UPDATE_FORM_CONFIG,
    //         variables: {
    //             designId,
    //             formConfigId,
    //             method,
    //             url,
    //             data,
    //             headers,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_UPDATE_FORM_CONFIG');

    //     return result.data.updateFormConfig;
    // },
    // async deleteFormConfig(designId, formConfigId) {
    //     const result = await apollo.mutate({
    //         mutation: DELETE_FORM_CONFIG,
    //         variables: {
    //             designId,
    //             formConfigId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_DELETE_FORM_CONFIG');

    //     return result.data.deleteFormConfig;
    // },
    /*=============================================m_ÔÔ_m=============================================\
        Page
    \================================================================================================*/
    // async getPage(designId, designVersionId, pageId) {
    //     const result = await apollo.query({
    //         query: GET_PAGE,
    //         variables: {
    //             designId,
    //             designVersionId,
    //             pageId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_PAGE');

    //     return result.data.getPage;
    // },
    // async getPageWithLinkedSections(designId, sectionId) {
    //     const result = await apollo.query({
    //         query: GET_PAGE_WITH_LINKED_SECTION,
    //         variables: {
    //             designId,
    //             sectionId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_GET_PAGE_WITH_LINKED_SECTION');

    //     return result.data.getPageWithLinkedSections;
    // },
    // async setHomePage(designId, designVersionId, homePageId) {
    //     const result = await apollo.mutate({
    //         mutation: SET_HOME_PAGE,
    //         variables: {
    //             designId,
    //             designVersionId,
    //             homePageId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_SET_HOME_PAGE');

    //     return result.data.setHomePage;
    // },
    // async createPage(designId, designVersionId, fromPageId, name, route, langs, title, cmsDataSetPath) {
    //     const result = await apollo.mutate({
    //         mutation: CREATE_PAGE,
    //         variables: {
    //             designId,
    //             designVersionId,
    //             fromPageId,
    //             name,
    //             route,
    //             langs,
    //             title,
    //             cmsDataSetPath,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_CREATE_PAGE');

    //     return result.data.createPage;
    // },
    // async updatePage(designId, designVersionId, pageId, data) {
    //     const result = await apollo.mutate({
    //         mutation: UPDATE_PAGE,
    //         variables: {
    //             designId,
    //             designVersionId,
    //             pageId,
    //             data,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_UPDATE_PAGE');

    //     return result.data.updatePage;
    // },
    // async deletePage(designId, designVersionId, pageId) {
    //     const result = await apollo.mutate({
    //         mutation: DELETE_PAGE,
    //         variables: {
    //             designId,
    //             designVersionId,
    //             pageId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_DELETE_PAGE');

    //     return result.data.deletePage;
    // },
    /*=============================================m_ÔÔ_m=============================================\
        Template
    \================================================================================================*/
    async getTemplates() {
        const result = await apollo.query({
            query: GET_TEMPLATES,
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_TEMPLATES');

        return result.data.getTemplates;
    },
    // async createTemplate(displayName, description, designId, organizationId, preview) {
    //     const result = await apollo.mutate({
    //         mutation: CREATE_TEMPLATE,
    //         variables: {
    //             displayName,
    //             description,
    //             designId,
    //             organizationId,
    //             preview,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_CREATE_TEMPLATE');

    //     return result.data.createTemplate;
    // },
    // async updateTemplate(templateId, displayName, description, designId, organizationId, preview) {
    //     const result = await apollo.mutate({
    //         mutation: UPDATE_TEMPLATE,
    //         variables: {
    //             templateId,
    //             displayName,
    //             description,
    //             designId,
    //             organizationId,
    //             preview,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_UPDATE_TEMPLATE');

    //     return result.data.updateTemplate;
    // },
    // async deleteTemplate(templateId) {
    //     const result = await apollo.mutate({
    //         mutation: DELETE_TEMPLATE,
    //         variables: {
    //             templateId,
    //         },
    //         fetchPolicy: 'no-cache',
    //     });
    //     if (!result) throw new Error('FAILED_TO_DELETE_TEMPLATE');

    //     return result.data.deleteTemplate;
    // },

    /*=============================================m_ÔÔ_m=============================================\
        SELF HOST
    \================================================================================================*/
    async isExportZipExist(designId, cacheVersion, raw) {
        const result = await apollo.query({
            query: IS_EXPORT_ZIP_EXIST,
            variables: {
                designId,
                cacheVersion,
                raw,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_IS_EXPORT_ZIP_EXIST');

        return result.data.isExportZipExist.isExportZipExist;
    },
    async getExportZipStatus(designId, cacheVersion, raw) {
        const result = await apollo.query({
            query: GET_EXPORT_ZIP_STATUS,
            variables: {
                designId,
                cacheVersion,
                raw,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_EXPORT_ZIP_STATUS');

        return result.data.getExportZipStatus;
    },
    async generateExportZip(designId, cacheVersion, raw) {
        const result = await apollo.mutate({
            mutation: GENERATE_EXPORT_ZIP,
            variables: {
                designId,
                cacheVersion,
                raw,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GENERATE_EXPORT_ZIP');

        return result.data.generateExportZip;
    },
    async setActiveCacheVersion(designId, cacheVersion, env) {
        const result = await apollo.mutate({
            mutation: SET_ACTIVE_CACHE_VERSION,
            variables: {
                designId,
                cacheVersion,
                env,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_SET_ACTIVE_CACHE_VERSION');

        return result.data.setActiveCacheVersion;
    },
    async getCacheVersions(designId) {
        const result = await apollo.query({
            query: GET_CACHE_VERSIONS,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_EXPORT_ZIP_STATUS');

        return result.data.getCacheVersions;
    },
    async getSelfHostDomain(designId) {
        const result = await apollo.query({
            query: GET_SELF_HOST_DOMAIN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_SELF_HOST_DOMAIN');

        return result.data.getSelfHostDomain;
    },
    async updateSelfHostDomain(designId, domain, stagingDomain) {
        const result = await apollo.mutate({
            mutation: UPDATE_SELF_HOST_DOMAIN,
            variables: {
                designId,
                domain,
                stagingDomain,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_SELF_HOST_DOMAIN');

        return result.data.updateSelfHostDomain;
    },
};
