<template>
    <div class="ww-manager-github-btn" :style="{ width: width + 'px' }">
        <div class="btn first" @click="selectOrga()" :class="{ valid: this.d_orga }">
            <div class="content">
                <span class="title">Organization</span>
                <span v-if="!d_loadingOrgs">{{ d_orga ? d_orga : 'Select...' }}</span>
                <span class="icon fas fa-spinner fa-spin" v-if="d_loadingOrgs"></span>
            </div>
        </div>
        <div class="btn" @click="d_orga && selectRepo()" :class="{ disabled: !this.d_orga, valid: this.d_repo }">
            <div class="content">
                <span class="title">Repository</span>
                <span v-if="!d_loadingRepos">{{ d_repo ? d_repo : 'Select...' }}</span>
                <span class="icon fas fa-spinner fa-spin" v-if="d_loadingRepos"></span>
            </div>
        </div>
        <div
            class="btn last"
            @click="d_repo && selectBranch()"
            :class="{ disabled: !this.d_repo, valid: this.d_branch }"
        >
            <div class="content">
                <span class="title">Branch</span>
                <span v-if="!d_loadingBranches">{{ d_branch ? d_branch : 'Select...' }}</span>
                <span class="icon fas fa-spinner fa-spin" v-if="d_loadingBranches"></span>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/services';

export default {
    name: 'wwManagerGithubButton',
    props: {
        width: [Number, String],
        invert: [Boolean, String],
        organization: String,
        repository: String,
        branch: String,
    },
    data() {
        return {
            d_orga: null,
            d_orgaIsOrg: false,
            d_repo: null,
            d_branch: null,
            d_githubConnected: false,

            d_githubOrgs: {
                type: 'text',
                values: [],
            },
            d_loadingOrgs: false,
            d_githubRepos: {
                type: 'text',
                values: [],
            },
            d_loadingRepos: false,
            d_githubBranches: {
                type: 'text',
                values: [],
            },
            d_loadingBranches: false,
        };
    },
    computed: {
        githubPathOk() {
            return this.organization && this.repository && this.branch;
        },
    },
    watch: {},
    methods: {
        async init() {
            this.d_orga = this.organization || null;
            this.d_repo = this.repository || null;
            this.d_branch = this.branch || null;

            this.d_githubConnected = await services.wwGithub.getUser();

            if (this.d_orga) {
                const orgs = await services.wwGithub.getOrganizations();
                for (const org of orgs) {
                    if (org.login == this.d_orga) {
                        this.d_orgaIsOrg = org.isOrg;
                    }
                }
            }
        },

        async selectOrga() {
            if (!this.d_githubConnected) {
                await this.connectGithub();
            }

            if (!this.d_githubConnected) {
                wwLib.wwLog.error('Error');
            }

            try {
                if (!this.d_githubOrgs.values.length) {
                    this.d_loadingOrgs = true;
                    const orgs = await services.wwGithub.getOrganizations();
                    if (!orgs || !orgs.length) {
                        return false;
                    }

                    let first = true;
                    for (const org of orgs) {
                        this.d_githubOrgs.values.push({
                            value: org,
                            default: first,
                            text: {
                                en: org.login,
                            },
                        });
                        first = false;
                    }
                    this.d_loadingOrgs = false;
                }

                const selectedOrga = await wwLib.wwPopupSelects.open(this.d_githubOrgs);

                if (this.d_orga != selectedOrga.login) {
                    this.d_orga = selectedOrga.login;
                    this.d_orgaIsOrg = selectedOrga.isOrg;

                    this.d_githubRepos.values = [];
                    this.d_githubBranches.values = [];

                    this.d_repo = null;
                    this.d_branch = null;

                    this.$emit('change', {
                        organization: '',
                        repository: '',
                        branch: '',
                    });
                }
                return true;
            } catch (error) {
                this.d_loadingOrgs = false;
                return false;
            }
        },

        async selectRepo() {
            if (!this.d_githubConnected) {
                await this.connectGithub();
            }

            if (!this.d_githubConnected) {
                wwLib.wwLog.error('Error');
            }

            try {
                if (!this.d_githubRepos.values.length) {
                    this.d_loadingRepos = true;

                    let repos;
                    if (this.d_orgaIsOrg) {
                        repos = await services.wwGithub.getOrgRepos(this.d_orga);
                    } else {
                        repos = await services.wwGithub.getUserRepos(this.d_orga);
                    }

                    if (!repos || !repos.length) {
                        return false;
                    }

                    let first = true;
                    for (const repo of repos) {
                        this.d_githubRepos.values.push({
                            value: repo,
                            default: first,
                            text: {
                                en: repo.name,
                            },
                        });
                        first = false;
                    }
                    this.d_loadingRepos = false;
                }

                const selectedRepo = await wwLib.wwPopupSelects.open(this.d_githubRepos);

                if (this.d_repo != selectedRepo.name) {
                    this.d_repo = selectedRepo.name;

                    this.d_githubBranches.values = [];

                    this.d_branch = null;

                    this.$emit('change', {
                        organization: '',
                        repository: '',
                        branch: '',
                    });
                }
                return true;
            } catch (error) {
                this.d_loadingRepos = false;
                return false;
            }
        },

        async selectBranch() {
            if (!this.d_githubConnected) {
                await this.connectGithub();
            }

            if (!this.d_githubConnected) {
                wwLib.wwLog.error('Error');
            }

            try {
                if (!this.d_githubBranches.values.length) {
                    this.d_loadingBranches = true;
                    let branches = await services.wwGithub.getRepoBranches(this.d_orga, this.d_repo);

                    if (!branches || !branches.length) {
                        branches = [
                            {
                                name: 'master',
                            },
                        ];
                    }

                    let first = true;
                    for (const branch of branches) {
                        this.d_githubBranches.values.push({
                            value: branch,
                            default: first,
                            text: {
                                en: branch.name,
                            },
                        });
                        first = false;
                    }
                    this.d_loadingBranches = false;
                }

                const selectedBranch = await wwLib.wwPopupSelects.open(this.d_githubBranches);

                this.d_branch = selectedBranch.name;

                this.$emit('change', {
                    organization: this.d_orga,
                    repository: this.d_repo,
                    branch: this.d_branch,
                });

                return true;
            } catch (error) {
                this.d_loadingBranches = false;
                return false;
            }
        },

        async connectGithub() {
            try {
                this.d_loadingOrgs = true;
                let user = this.$store.getters['v2/getUser'];
                //Wait for user to be present in store. Not always true if button is clicked rapidly.
                while (!user || !user.id) {
                    user = await new Promise(resolve => {
                        setTimeout(() => {
                            resolve(this.$store.getters['v2/getUser']);
                        }, 1000);
                    });
                }

                await services.wwGithub.openGithubWindow(user.id);
                this.d_githubConnected = await services.wwGithub.getUser();

                this.d_loadingOrgs = false;
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.ww-manager-github-btn {
    cursor: pointer;
    display: flex;
    .btn {
        border: 1px solid var(--ww-color-blue-500);
        position: relative;
        overflow: hidden;
        flex-grow: 1;
        background-color: var(--ww-color-blue-500);
        transition: all 0.3s ease;
        color: var(--ww-color-white);

        .content {
            height: calc(100% - 10px);
            padding: 5px 20px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: bold;
            flex-direction: column;
            align-items: flex-start;

            .title {
                text-transform: none;
                font-weight: normal;
                opacity: 0.8;
                font-size: 10px;
            }

            .icon {
                font-size: 16px;
            }
        }

        &:hover {
            color: var(--ww-color-blue-500);
            background-color: var(--ww-color-white);
        }

        &.valid {
            border: 1px solid var(--ww-color-green-500);
            background-color: var(--ww-color-green-500);
            &:hover {
                background-color: var(--ww-color-white);
                color: var(--ww-color-green-500);
            }
        }

        &.disabled {
            border: 1px solid var(--ww-color-dark-400);
            cursor: not-allowed;
            background-color: var(--ww-color-dark-400);
            &:hover {
                background-color: var(--ww-color-white);
                color: var(--ww-color-dark-400);
            }
        }

        &.first {
            border-radius: var(--ww-border-radius-02) 0 0 var(--ww-border-radius-02);
        }
        &.last {
            border-radius: 0 var(--ww-border-radius-02) var(--ww-border-radius-02) 0;
        }
        &:not(.last) {
            margin-right: 2px;
        }
    }
}
</style>
